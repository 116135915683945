export const favicon = 'favicon.png';
export const title = 'Sheme Hamitaj | Personal Page';
export const meta = [
  {
    name: 'description',
    content: 'Sheme Hamitaj is a Computer Engineer based in Albania.'
  },
  {
    name: 'keywords',
    content: 'sheme,hamitaj,personal,site,developer,software,web,desktop,mobile,fast,reliable,resume,tirana,albania,github,pages,shqiperi,faqe,personale,tirane,zhvillues,programues,programe,profesional,sherbim'
  }
];
export const landingPage = 'about';
