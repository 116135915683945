export default {
  name: "about", // do not change
  pageTitle: "About",
  icon: "mdi-account-cowboy-hat",

  greeting: "Hello, I am Sheme Hamitaj.",
  description:
    "Computer Engineer experienced in Web, Mobile & Desktop Apps, Robotics(ROS), Computer Vision, Embedded Systems. Programming languages include Javascript, Typescript, PHP, C, C++, Java, Python, C# etc. Currently working with Vue & NuxtJS and PHP & Laravel.",
  birthYear: "1996",
  residence: "Albania",
  freelance: "Unavailable",
  address: "Tirane, ALB",
  quote: "An ambitious man either wins or learns.",
  hobbies: [
    {
      title: "Chess",
      icon: "mdi-chess-knight",
      description:
        "My father taught me this flawless game when I was 4 years old and I have been playing ever since.",
    },
    {
      title: "Table Tennis",
      icon: "mdi-table-tennis",
      description: "A terrific game to play, keeps one's mind sharp and ready.",
    },
    {
      title: "Football",
      icon: "mdi-soccer",
      description:
        "Who would not enjoy a match with friends on a friday evening?",
    },
  ],
};
