export default {
  name: 'contact', // do not change
  pageTitle: 'Contact',
  header: 'Drop a Line',
  icon: 'mdi-send',

  mapsIframe: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d95871.48845589603!2d19.747783295014003!3d41.33096097063582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350310470fac5db%3A0x40092af10653720!2sTirana!5e0!3m2!1sen!2s!4v1601648127003!5m2!1sen!2s"></iframe>',
  address: 'Tirane, Albania',
  hours: '9AM - 5PM',
  email: 'shen.i@live.com',
  tel: '+355 68 468 3347'
};
