<div class="card">
    <slot></slot>
</div>

<style>
    .card {
        background: var(--lighest-white-overlay);
        padding: 20px;
        border-radius: var(--radius);
        box-shadow: 0 2px 4px var(--shadow-color);
    }
</style>
